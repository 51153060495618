import React from 'react'
import styled from 'styled-components'
import ViewCounter from '../ViewCounter'

function Advertise() {
    return (
        <>
        
       
        <Container>
        <Logo> 
        <Img>
                <a href='/'>
                    <img src="/konzologo.png" alt="/" />
                 </a>
                 </Img>
        </Logo>
        <ViewCounter path="/Advertise" />
            <Content>
            <strong> ADVERTISE WITH US</strong>
<p>We are the LIONS of advertisement on the web in Kasese</p>
<p>Talk to us on konzovibez@gmail.com </p>
            </Content>
            </Container>

        </>
    )
}

export default Advertise


const Img = styled.div`
justify-content: center;

img {
  width:100%;
height:150px;
@media screen and (max-width:768px){
height: 80px;
}


}

`
const Logo = styled.div`
justify-content: center;
`

const Content = styled.div`
margin:50px;
strong {
    text-decoration: none;
        color: #DB5D5D;
    font-family: "Comfortaa", Sans-serif;
    font-size:40px;

}

p {
    text-decoration: none;
        color: black;
    font-family: "Comfortaa", Sans-serif;
    font-size:20px;
}
`

const Container = styled.div`
background-color:#030617 ;
background-repeat: repeat;

//background: url('./images/ywd logo.PNG') center center/cover no-repeat;
height: 100%;
width: 100%;
/* margin-left: 50px;
margin-right: 50px; */
display: flex;
justify-content: center;
flex-direction:column;
align-items:center;
cursor: pointer;
border-radius: 4px;
object-fit: contain;
position: relative;
color: #fff;
& :hover {

    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

}
`;
