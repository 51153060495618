import {React, useEffect} from 'react'
import firebase from '../../firebase'
import {useDispatch} from "react-redux"
import {setRecents} from "../../features/recent/recentSlice"
import RecentlyAdded from '../audio/Songs/RecentlyAdded'
import GoogleAd2 from '../GoogleAd2';
import GoogleAd from '../GoogleAd';

function RecentAddedHitsPage2() {
  const dispatch = useDispatch();

    useEffect(()=>{
      firebase
      .firestore()
      .collection("recents").onSnapshot((snapshot) => {
          let tempRecents = snapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
          });
          console.log(tempRecents);
          dispatch(
              setRecents(tempRecents)
          );
      })
  }, [dispatch])


    return (
        <>
         <GoogleAd2 />

         <RecentlyAdded />
         <GoogleAd />
        </>
    )
}

export default RecentAddedHitsPage2;
