import React from 'react';
import styled from 'styled-components';

const AdWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  display: block;
  
  .adsbygoogle {
    display: block;
    min-width: 250px;
    width: 100%;
    background: #030617;
  }
`;

export default class GoogleAd2 extends React.Component {
  componentDidMount() {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Adsbygoogle error:', e);
    }
  }

  render() {
    return (
      <AdWrapper>
        <ins
          className="adsbygoogle"
          style={{ display: 'block', minWidth: '250px', width: '100%', background: '#030617' }}
          data-ad-format="fluid"
          data-ad-layout-key="-gk+2d+39-5t+2g"
          data-ad-client="ca-pub-2968429081843062"
          data-ad-slot="8975569481"
        />
      </AdWrapper>
    );
  }
}