import React from 'react';

export default class GoogleAd extends React.Component {
  
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
      <>
            {/* <h1 style={{fontSize:'14px', color: "white", fontweight: "900", background:"#030617"}}>Advertisement</h1> */}
      
        <div className="Ad"style={{background:"#030617"}}>

    <ins className="adsbygoogle"
     style={{display:"block", background:"#030617"}}
    data-ad-client="ca-pub-2968429081843062"
         data-ad-slot="1444144967"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
     </div>
     </>

 );
  }
}
