import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

const FooterContainer = styled.div`
    background-color: #202938;
    width: 100%;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);

    @media screen and (max-width: 768px) {
        padding: 1rem 0.5rem;
        gap: 0.75rem;
    }
`

const Footer2small = styled.small`
    text-align: center;
    display: flex;
    color: #fff;
    font-size: 0.85rem;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
    flex-wrap: wrap;
    padding: 0 1rem;
    
    @media screen and (max-width: 768px) {
        font-size: 0.7rem;
        line-height: 1.4;
    }
`

const NavItem = styled.li`
    display: flex;
    position: relative;
    
    &:not(:last-child)::after {
        content: '•';
        color: #4a5568;
        margin: 0 0.5rem;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 768px) {
        &:not(:last-child)::after {
            margin: 0 0.25rem;
        }
    }

    @media screen and (max-width: 480px) {
        &:not(:last-child)::after {
            margin: 0 0.15rem;
        }
    }
`;

const NavLinks = styled(Link)`
    color: #cbd5e0;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    white-space: nowrap;

    &:hover {
        color: #fff;
        background-color: rgba(255,255,255,0.1);
    }

    @media screen and (max-width: 768px) {
        padding: 0.2rem 0.3rem;
        font-size: 0.7rem;
    }

    @media screen and (max-width: 480px) {
        padding: 0.15rem 0.25rem;
        font-size: 0.65rem;
    }
`;

const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.25rem;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 768px) {
        gap: 0.15rem;
    }
`

const Footer2Links = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 0.5rem;
`

const StyledLink = styled.a`
    color: #f97316;
    text-decoration: none;
    font-weight: 600;
    margin: 0 0.3rem;
    transition: color 0.2s ease;
    white-space: nowrap;

    &:hover {
        color: #fb923c;
    }

    @media screen and (max-width: 768px) {
        margin: 0 0.2rem;
    }
`

const Footer2 = () => {
    return (
        <FooterContainer>
            <Footer2small>
                This is the official website of KONZO MOBI APP Crafted by{' '}
                <StyledLink 
                    href='https://www.kihembo.com' 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    KIHEMBO WILBERT
                </StyledLink>
            </Footer2small>
            
            <Footer2Links>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='/about'>About Us</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/contacts'>Upload Music</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/privacy-policy'>Privacy</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/terms-and-conditions'>Terms</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/advertise'>Advertise</NavLinks>
                    </NavItem>
                </NavMenu>
            </Footer2Links>
            
            <Footer2small>
                KONZO MOBI {new Date().getFullYear()} All rights reserved.
            </Footer2small>
        </FooterContainer>
    )
}

export default Footer2
