import styled from "styled-components";

export const CardS = styled.div`
  background-color: #030617;
  padding: 0.1rem;
  background-repeat: repeat;
  text-decoration: none !important;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  a {
    text-decoration: none !important;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 1fr));
  gap: 8px;
  padding: 0.2rem;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(3, minmax(160px, 1fr));
  }
  
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(170px, 1fr));
  }
  
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(5, minmax(180px, 1fr));
  }
  
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(6, minmax(190px, 1fr));
  }
  
  @media screen and (min-width: 1400px) {
    grid-template-columns: repeat(7, minmax(200px, 1fr));
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  transition: none;
  
  &:hover {
    transform: none;
  }
`;

export const CardItems = styled.ul`
  text-decoration: none;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  padding: 8px;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &.loading {
    background: rgba(255, 255, 255, 0.05);
    animation: pulse 1.5s ease-in-out infinite;
  }

  @keyframes pulse {
    0% {
      background: rgba(255, 255, 255, 0.05);
    }
    50% {
      background: rgba(255, 255, 255, 0.1);
    }
    100% {
      background: rgba(255, 255, 255, 0.05);
    }
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: none;
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: none;
  
  &:hover {
    transform: none;
  }
`;

export const Text = styled.div`
  text-decoration: none;
  text-align: center;
  margin-top: 8px;
  font-color: white;

  h1 {
    font-size: 0.88rem;
    color: #fff;
    text-decoration: none !important;
    font-weight: 400;
    margin: 0;
    padding: 4px;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    letter-spacing: 0;
    line-height: 1.3;
    min-height: 2.6rem;
  }
`;

export const Header = styled.h1`
  text-decoration: none;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  color: white;
  margin: 0.2rem 0 0.4rem;
  letter-spacing: 0;
  
  h4 {
    margin: 0;
    padding: 0;
  }
`;

// Skeleton Loading Styles
export const SkeletonCard = styled(CardItem)`
  background: rgba(255, 255, 255, 0.05);
  animation: pulse 1.5s ease-in-out infinite;
`;

export const SkeletonImg = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.05) 25%,
    rgba(255, 255, 255, 0.1) 37%,
    rgba(255, 255, 255, 0.05) 63%
  );
  background-size: 400% 100%;
  animation: shimmer 1.4s ease infinite;
  border-radius: 2px;

  @keyframes shimmer {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

export const SkeletonText = styled.div`
  height: 6px;
  margin-top: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.05) 25%,
    rgba(255, 255, 255, 0.1) 37%,
    rgba(255, 255, 255, 0.05) 63%
  );
  background-size: 400% 100%;
  animation: shimmer 1.4s ease infinite;
  border-radius: 1px;
`;
