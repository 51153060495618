import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const ViewCountWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  margin: 8px 0;
`;

const ViewIcon = styled.span`
  margin-right: 6px;
`;

const ViewCounter = ({ path }) => {
  const [viewCount, setViewCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const incrementViewCount = async () => {
      try {
        // Check if this page has been viewed in this session
        const sessionKey = `viewed_${path}`;
        if (sessionStorage.getItem(sessionKey)) {
          return;
        }

        const response = await axios.post('/.netlify/functions/incrementViewCount', {
          path,
        });

        if (response.data.count) {
          setViewCount(response.data.count);
          // Mark this page as viewed in this session
          sessionStorage.setItem(sessionKey, 'true');
        }
      } catch (err) {
        console.error('Failed to increment view count:', err);
        setError('Failed to load view count');
      }
    };

    incrementViewCount();
  }, [path]);

  if (error) {
    return null;
  }

  return (
    <ViewCountWrapper>
      <ViewIcon>👁️</ViewIcon>
      {viewCount} {viewCount === 1 ? 'view' : 'views'}
    </ViewCountWrapper>
  );
};

export default ViewCounter;
