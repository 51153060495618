import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import TextAnimation from './TextAnimation';
import Button from '@mui/material/Button';
import { styled as muiStyled } from '@mui/material/styles';

const StyledButton = muiStyled(Button)(({ theme }) => ({
  marginTop: '20px',
  padding: '10px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const Content = () => {
  const styles = {
    border: 'none',
    overflow: 'hidden',
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <HeroContainer>
        <LoopVideo />
        <HeroContent>
          <Title variants={itemVariants}>
            <h1>SNOW FM</h1>
          </Title>
          <Desc variants={itemVariants}>
            <TextAnimation />
          </Desc>
          <motion.div variants={itemVariants}>
            <iframe
              title="snowfm"
              src="https://sp.streams.ovh/cp/widgets/player/single/?p=8032"
              allow="accelerometer;"
              style={styles}
            ></iframe>
          </motion.div>
          <ContentWrapper>
            <Desc1 variants={itemVariants}>
              Embracing Diversity: Enchanting Entertainment with Disability Inclusion!
            </Desc1>
            <Desc2 variants={itemVariants}>
              Please click 'Donate' to contribute and help us acquire radio equipment
            </Desc2>
            <motion.div variants={itemVariants}>
              <StyledButton
                variant="contained"
                href="https://www.paypal.com/donate/?hosted_button_id=UBRGNLABD5ZLE"
                color="success"
              >
                Donate
              </StyledButton>
            </motion.div>
          </ContentWrapper>
        </HeroContent>
      </HeroContainer>
    </motion.div>
  );
};

export default Content;

const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
  max-width: 600px;
  padding: 0 16px;
`;

const HeroContainer = styled(motion.div)`
  background: linear-gradient(180deg, #030617 0%, #0a1128 100%);
  height: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
`;

const LoopVideo = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0.4;
`;

const HeroContent = styled(motion.div)`
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  animation: fadeIn 0.8s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Title = styled(motion.div)`
  text-align: center;

  h1 {
    color: #add8e6;
    font-size: 1.69rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin: 0;
    letter-spacing: 1px;

    @media screen and (max-width: 768px) {
      font-size: 1.42rem;
    }
  }
`;

const Desc = styled(motion.div)`
  color: #fff;
  font-size: 20px;
  text-align: center;
  max-width: 800px;

  @media screen and (max-width: 960px) {
    font-size: 0.71rem;
  }
`;

const Desc1 = styled(motion.h6)`
  color: white;
  font-size: 0.93rem;
  margin: 0;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    font-size: 0.71rem;
  }
`;

const Desc2 = styled(motion.h6)`
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.73rem;
  margin: 0;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: 0.61rem;
  }
`;