import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSongs } from '../../../features/song/songSlice';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ChartContainer = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  padding: 1rem;
  margin: 1rem auto;
  max-width: 800px;
  width: 100%;
`;

const ChartHeading = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
`;

const ChartList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem;
  padding: 0.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ChartItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
  }
`;

const Rank = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: rgba(75, 192, 192, 0.8);
  min-width: 2rem;
  text-align: center;
`;

const SongInfo = styled.div`
  flex: 1;
  overflow: hidden;
`;

const SongTitle = styled.h3`
  color: white;
  font-size: 0.9rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ViewCount = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
`;

const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
  background: rgba(255, 255, 255, 0.1);
`;

const LoadingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem;
  padding: 0.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const LoadingItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  height: 56px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

function formatNumber(num) {
  if (!num) return '0';
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
}

function LoadingSkeleton() {
  return (
    <LoadingContainer>
      {[...Array(4)].map((_, index) => (
        <LoadingItem key={index} />
      ))}
    </LoadingContainer>
  );
}

function TopCharts() {
  const songs = useSelector(selectSongs);
  const [topSongs, setTopSongs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (songs && Array.isArray(songs) && songs.length > 0) {
      try {
        const sorted = [...songs]
          .filter(song => song && song.title && (song.views || song.visits))
          .sort((a, b) => ((b.views || b.visits) || 0) - ((a.views || a.visits) || 0))
          .slice(0, 10);
        setTopSongs(sorted);
      } catch (error) {
        console.error('Error processing songs:', error);
      }
    }
    setLoading(false);
  }, [songs]);

  if (loading) {
    return (
      <ChartContainer>
        <ChartHeading>Top 10 Most Played Songs</ChartHeading>
        <LoadingSkeleton />
      </ChartContainer>
    );
  }

  if (!Array.isArray(songs) || topSongs.length === 0) {
    return null;
  }

  return (
    <ChartContainer>
      <ChartHeading>Top 10 Most Played Songs</ChartHeading>
      <ChartList>
        {topSongs.map((song, index) => (
          <ChartItem to={`/konzohits/${song.id}`} key={song.id || index}>
            <Rank>{index + 1}</Rank>
            <Thumbnail 
              src={song.imageUrl} 
              alt={song.title} 
              loading="lazy"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/default-song-image.jpg';
              }} 
            />
            <SongInfo>
              <SongTitle>{song.title}</SongTitle>
              <ViewCount>{formatNumber(song.views || song.visits)} plays</ViewCount>
            </SongInfo>
          </ChartItem>
        ))}
      </ChartList>
    </ChartContainer>
  );
}

export default TopCharts;
