import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setNonstops } from '../../features/nonstop/nonstopSlice';
import useFirestore from '../../hooks/useFirestore';
import FirestoreError from '../common/FirestoreError';
import styled from 'styled-components';
import Nonstops from '../audio/Songs/Nonstops';
import GoogleAd2 from '../../components/GoogleAd2';
import Nav3 from '../Navs/SideView/Nav3';
import ViewCounter from '../ViewCounter';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #fff;
  font-size: 1.2rem;
`;

function NonstopPage() {
  const dispatch = useDispatch();
  const { data: channels, loading, error, retry } = useFirestore('channels');

  React.useEffect(() => {
    if (channels) {
      dispatch(setNonstops(channels));
    }
  }, [channels, dispatch]);

  return (
    <div style={{background:'#030617'}}>
      <Helmet>
        <title>Watch TV Channels in Western Uganda</title>
        <meta
          name="description"
          content="Best of Kasese entertainment"
        />
        <meta name="keywords" content="mp3 song of, mp3 song in, mp3 song, Mp3 song, download mp3 song, mp3 song download, mp3 song download free"/>
        <link rel="apple-touch-icon" href="/logo512.jpg" />
        <style type="text/css"></style>
      </Helmet>

      <Nav3 />

      {loading && (
        <LoadingContainer>
          Loading channels...
        </LoadingContainer>
      )}

      {error === 'quota-exceeded' && (
        <FirestoreError onRetry={retry} />
      )}

      {!loading && !error && <Nonstops />}

      <GoogleAd2 />
      <ViewCounter path="/channels" />
    </div>
  );
}

export default NonstopPage
