import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  height: 288px; /* Set your desired height */
`;

const ImageBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('${(props) => props.imageUrl}') center no-repeat;
  background-size: cover;
  border-radius: 10px; /* Set your desired border-radius */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px; /* Set your desired border-radius */
  background: linear-gradient(to bottom, rgba(169, 169, 169, 0.5), rgba(0, 0, 0, 0.8));
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.p`
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
`;

const LinkOverlay = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const FeaturedPostCard = ({ recent }) => {
  if (!recent || !recent.image || !recent.image) {
    return (
      <Container>
        <p style={{ color: 'white' }}>Featured Post Data Missing</p>
      </Container>
    );
  }

  return (
    <Container>
      <ImageBackground imageUrl={recent.image} />
      <Overlay />
      <Content>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={recent.image} alt={recent.title} />
          <Title>{recent.title}</Title>
        </div>
      </Content>
      <LinkOverlay onClick={() => window.location.href = `/musics/${recent.id}`} />
    </Container>
  );
};

FeaturedPostCard.propTypes = {
  recent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeaturedPostCard;
