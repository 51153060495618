import {React, } from 'react'
import Nav3 from '../Navs/SideView/Nav3'
import Content from '../snowfm/Content2'
import GoogleAd from '../GoogleAd'
import RecentAddedHitsPage2 from './RecentlyAddedHitsPage2'
import RecentSlides from '../audio/Songs/Silder/RecentSilder'
import Blog from './Blog'
import TopCharts from '../audio/Songs/TopCharts'
import AppDownloadBanner from '../AppDownloadBanner'

function Home() {

     return (
        <div style={{background:'#030617'}}>
  
        <Nav3 />
        <Content />
        <AppDownloadBanner />
        <RecentSlides/>
        <Blog/>
        <TopCharts />
        <RecentAddedHitsPage2 />        
        <GoogleAd/> 
        </div>
    )
}

export default Home
