import React, { useEffect, useState } from "react";
import "./Converter.css";
import Nav3 from '../../../Navs/SideView/Nav3';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import firebase from '../../../../firebase';
import styled from "styled-components";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Modal from "./Modal";
import ViewCounter from '../../../ViewCounter';

const Container = styled.div`
  background-color: #030617;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  background: linear-gradient(145deg, #1c2237 0%, #151a2b 100%);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
`;

const StationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  backdrop-filter: blur(10px);

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
`;

const StationImage = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: linear-gradient(45deg, rgba(75, 192, 192, 0.2), rgba(75, 192, 192, 0));
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media (max-width: 600px) {
    width: 120px;
    height: 120px;
  }
`;

const StationLogo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid rgba(75, 192, 192, 0.3);
`;

const StationDetails = styled.div`
  flex: 1;
`;

const StationName = styled.h1`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 600;
  
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const LiveBadge = styled.div`
  display: inline-flex;
  align-items: center;
  background: rgba(75, 192, 192, 0.1);
  color: rgb(75, 192, 192);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 15px;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: rgb(75, 192, 192);
    border-radius: 50%;
    margin-right: 8px;
    animation: blink 1.5s infinite;
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.4; }
    100% { opacity: 1; }
  }
`;

const PlayerContainer = styled.div`
  .rhap_container {
    background: rgba(255, 255, 255, 0.03);
    box-shadow: none;
    padding: 20px;
    border-radius: 16px;
  }

  .rhap_main-controls-button {
    color: rgb(75, 192, 192);
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .rhap_progress-filled {
    background-color: rgb(75, 192, 192);
  }

  .rhap_progress-indicator {
    background: rgb(75, 192, 192);
  }

  .rhap_time {
    color: rgba(255, 255, 255, 0.7);
  }

  .rhap_volume-button {
    color: rgba(255, 255, 255, 0.7);
  }

  .rhap_volume-bar {
    background: rgba(255, 255, 255, 0.1);
  }

  .rhap_volume-indicator {
    background: rgb(75, 192, 192);
  }
`;

const AdContainer = styled.div`
  width: 100%;
  max-width: 728px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

function OnlineRadioPlayer() {
  const { id } = useParams();
  const [radio, setRadio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection('stations')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setRadio(doc.data());
        } else {
          console.log("No such document in Firebase ");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [id]);

  return (
    <>
      <Modal />
      <Nav3 />
      {radio && (
        <>
          <Helmet>
            <title>{radio.title} Live Stream</title>
            <meta name="description" content={`Listen to ${radio.title} live stream - Best of Kasese entertainment`} />
            <meta property="og:title" content={`${radio.title} Live Stream`} />
            <meta property="og:image" content={radio.image} />
            <link rel="icon" href={radio.image} />
          </Helmet>
          
          <Container>
            <CardContainer>
              <StationInfo>
                <StationImage>
                  <StationLogo src={radio.image} alt={radio.title} />
                </StationImage>
                <StationDetails>
                  <StationName>{radio.title}</StationName>
                  <LiveBadge>LIVE</LiveBadge>
                </StationDetails>
              </StationInfo>

              <PlayerContainer>
                <AudioPlayer
                  src={radio.url}
                  autoPlay
                  showJumpControls={false}
                  customControlsSection={['MAIN_CONTROLS', 'VOLUME_CONTROLS']}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  layout="horizontal"
                />
              </PlayerContainer>
            </CardContainer>

            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8703718386"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>

            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8456694735"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>
            <ViewCounter path={`/radio/${id}`} />
          </Container>
        </>
      )}
    </>
  );
}

export default OnlineRadioPlayer;
