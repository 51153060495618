import React, { useState, useEffect } from 'react';
import './Trending.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSongs } from '../../../features/song/songSlice';
import styled from 'styled-components';
import {
    CardS,
    Header,
    CardContainer,
    CardItem,
    CardItems,
    CardWrapper,
    ImgWrapper,
    Img,
    Text,
    SkeletonCard,
    SkeletonImg,
    SkeletonText,
} from './Cardscss';

const SearchInputContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
`;

const NoResultsMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #666;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ArtistTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ArtistTag = styled.button`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 0.3rem 0.8rem;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

function LoadingSkeleton() {
  return Array(20).fill(null).map((_, index) => (
    <CardWrapper key={`skeleton-${index}`}>
      <CardItems>
        <SkeletonCard>
          <SkeletonImg />
          <SkeletonText />
        </SkeletonCard>
      </CardItems>
    </CardWrapper>
  ));
}

function Trending() {
  const songs = useSelector(selectSongs);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [commonArtists, setCommonArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState('');

  useEffect(() => {
    if (songs && songs.length > 0) {
      setIsLoading(false);
      extractCommonArtists(songs);
    }
  }, [songs]);

  const extractCommonArtists = (songs) => {
    const artistMap = new Map();

    songs.forEach(song => {
      if (!song || !song.title) return;
      
      const title = song.title.toLowerCase();
      // Common patterns for artist names in titles
      const patterns = [
        /ft\.\s*([^-\n]+)/i,
        /feat\.\s*([^-\n]+)/i,
        /by\s+([^-\n]+)/i,
        /\s*-\s*([^-\n]+)/
      ];

      patterns.forEach(pattern => {
        const match = title.match(pattern);
        if (match && match[1]) {
          const artists = match[1].split(/[,&]+/).map(artist =>
            artist.trim().replace(/\(.*?\)/g, '').trim()
          );

          artists.forEach(artist => {
            if (artist && artist.length > 2) {
              artistMap.set(artist, (artistMap.get(artist) || 0) + 1);
            }
          });
        }
      });
    });

    // Get artists that appear more than once
    const commonArtists = Array.from(artistMap.entries())
      .filter(([_, count]) => count > 1)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([artist]) => artist);

    setCommonArtists(commonArtists);
  };

  const handleArtistClick = (artist) => {
    if (selectedArtist === artist) {
      setSelectedArtist('');
      setSearchTerm('');
    } else {
      setSelectedArtist(artist);
      setSearchTerm(artist);
    }
  };

  const filteredSongs = React.useMemo(() => {
    return songs.filter(song =>
      song && song.title && song.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [songs, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="Wrapper">
      <CardS>
        <SearchInputContainer>
          <SearchInput
            id="searchInput"
            type="text"
            placeholder="Search here..."
            value={searchTerm}
            onChange={handleSearchChange}
            aria-label="Search songs"
          />
          <ArtistTags>
            {commonArtists.map((artist, index) => (
              <ArtistTag
                key={index}
                className={selectedArtist === artist ? 'active' : ''}
                onClick={() => handleArtistClick(artist)}
              >
                {artist}
              </ArtistTag>
            ))}
          </ArtistTags>
        </SearchInputContainer>

        <StyledLink to="/">
          <Header>
            <h4>Download Trending Music in Kasese A-Z</h4>
          </Header>
        </StyledLink>

        <CardContainer>
          {isLoading ? (
            <LoadingSkeleton />
          ) : filteredSongs.length > 0 ? (
            filteredSongs.map((song) => (
              song && song.id && (
                <CardWrapper key={song.id}>
                  <CardItems>
                    <CardItem>
                      <StyledLink to={`/music/${song.id}`}>
                        <ImgWrapper>
                          <Img src={song.image} alt={song.title} loading="lazy" />
                        </ImgWrapper>
                        <Text>
                          <h1>{song.title}</h1>
                        </Text>
                      </StyledLink>
                    </CardItem>
                  </CardItems>
                </CardWrapper>
              )
            ))
          ) : (
            <NoResultsMessage>
              No results found. <StyledLink to="/">Back to main search</StyledLink>
            </NoResultsMessage>
          )}
        </CardContainer>
      </CardS>
    </div>
  );
}

export default Trending;
