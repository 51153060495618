// RecentSlides.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { setRecents } from '../../../../features/recent/recentSlice';
import FeaturedSlides from './FeaturedSlides';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { motion } from 'framer-motion';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  backdrop-filter: blur(10px);
`;

const ErrorContainer = styled(motion.div)`
  padding: 20px;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 12px;
  margin: 20px 0;
  text-align: center;
  color: #ff6b6b;
  border: 1px solid rgba(255, 0, 0, 0.2);
`;

const SectionContainer = styled(motion.div)`
  margin: 20px 0;
  padding: 20px;
  background: linear-gradient(145deg, #1a1e2e 0%, #0d1017 100%);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  
  &::after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #4b7bec, #3867d6);
    margin: 10px auto;
    border-radius: 2px;
  }
`;

function RecentSlides() {
  const dispatch = useDispatch();
  const [randomRecents, setRandomRecents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecents = async () => {
      try {
        setLoading(true);
        const db = firebase.firestore();
        const recentCollection = db.collection('recents');
        
        const querySnapshot = await recentCollection.get();
        
        if (querySnapshot.empty) {
          setError('No recent items found');
          return;
        }

        const allRecents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const shuffledRecents = shuffleArray(allRecents);
        const numberOfRandomItems = 3;
        const randomRecentsItems = shuffledRecents.slice(0, numberOfRandomItems);

        dispatch(setRecents(randomRecentsItems));
        setRandomRecents(randomRecentsItems);
        setError(null);
      } catch (err) {
        console.error('Error fetching recents:', err);
        setError('Failed to load recent items. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRecents();
  }, [dispatch]);

  function shuffleArray(array) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress style={{ color: '#4b7bec' }} />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {error}
      </ErrorContainer>
    );
  }

  return (
    <SectionContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <SectionTitle>Latest Music</SectionTitle>
      <FeaturedSlides recents={randomRecents} />
    </SectionContainer>
  );
}

export default RecentSlides;
