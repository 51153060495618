import {React, useEffect} from 'react'
import { setSongs } from '../../features/song/songSlice'
import {useDispatch} from "react-redux"
import firebase from '../../firebase'
import Nav3 from '../Navs/SideView/Nav3'
import Trending from '../audio/Songs/Trending'
import ViewCounter from '../ViewCounter'

import {Helmet} from 'react-helmet'
import GoogleAd2 from '../GoogleAd2'
import styled from 'styled-components'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #030617;
  overflow-x: hidden;
  width: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`;

const AdContainer = styled.div`
  width: 100%;
  min-width: 250px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > div {
    min-width: 250px;
    width: 100%;
    max-width: 970px;
    margin: 0 auto;
  }

  ins {
    min-width: 250px !important;
    width: 100% !important;
  }
`;

function Song() {
    const dispatch = useDispatch();

    useEffect(()=>{
        firebase
      .firestore()
      .collection("songs").onSnapshot((snapshot) => {
            let tempSongs = snapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() };
            });
            console.log(tempSongs);
            dispatch(
                setSongs(tempSongs)
            );
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PageContainer>
            <Helmet>
                <title>Trending Songs in Kasese</title>
                <meta
                    name="description"
                    content="Best of Kasese, fully equipped entertainment online platform"
                />
                <meta 
                    name="keywords" 
                    content="mp3 song of, mp3 song in, mp3 song, Mp3 song, download mp3 song, mp3 song download, mp3 song download free"
                />
                <link rel="apple-touch-icon" href="/snow fm.png" />
            </Helmet>
            <Nav3 />
            <ContentContainer>
                <Trending />
                <AdContainer>
                    <GoogleAd2 />
                </AdContainer>
            </ContentContainer>
            <ViewCounter path="/konzohits" />
        </PageContainer>
    )
}

export default Song
