import React from 'react'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'
import ViewCounter from '../ViewCounter'
function Upload() {
    return (
        <> 
              <Helmet>
        
<meta name="keywords" content="Discover the vibrant musical talents of Kasese and Bundibugyo artists on Konzo Vibez. Enjoy the best local music and explore an extensive platform dedicated to promoting regional artists. Tune in to Snow FM Kasese, our online radio station advocating for disability rights. Join our community today" />
<meta

  name="description"
  content="Discover the vibrant musical talents of Kasese and Bundibugyo artists on Konzo Vibez. Enjoy the best local music and explore an extensive platform dedicated to promoting regional artists. Tune in to Snow FM Kasese, our online radio station advocating for disability rights. Join our community today"
  
/>
<link rel="icon" href="/logo192.png" />

        </Helmet>
  
        <Container>
        <Logo> 
        <Img>
                <a href='/'>
                    <img src="/konzologo.png" alt="/" />
                 </a>
                 </Img>
        </Logo>
        <ViewCounter path="/upload" />
            <Content>
            <strong> ADD YOUR SONG TO KONZO HITS</strong>
<p>Do you want to upload your music on our KONZO HITS platform?</p>
<p>Email your youtube music link at konzovibez@gmail.com </p>
            </Content>
            </Container>

        </>
    )
}

export default Upload


const Img = styled.div`
justify-content: center;

img {
  width:100%;
height:150px;
@media screen and (max-width:768px){
height: 80px;
}


}

`
const Logo = styled.div`
justify-content: center;
`

const Content = styled.div`
margin:50px;
strong {
    text-decoration: none;
        color: #DB5D5D;
    font-family: "Comfortaa", Sans-serif;
    font-size:40px;

}

p {
    text-decoration: none;
        color: black;
    font-family: "Comfortaa", Sans-serif;
    font-size:20px;
}
`

const Container = styled.div`
background-image: url("/white.jpg");
background-repeat: repeat;
min-height: 100vh;
padding: 20px;
@media only screen and (max-width: 992px) {

}
`;
