import React from 'react';
import styled from 'styled-components';
import { FaPaypal } from 'react-icons/fa';

const ErrorContainer = styled.div`
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  text-align: center;
  color: #fff;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ErrorTitle = styled.h3`
  color: #ff4444;
  margin: 0 0 8px 0;
  font-size: 1.2rem;
`;

const ErrorMessage = styled.p`
  margin: 0 0 16px 0;
  font-size: 0.95rem;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
`;

const RetryButton = styled.button`
  background: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: #ff6666;
    transform: translateY(-1px);
  }
`;

const DonateButton = styled.a`
  background: #0070ba;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.95rem;

  &:hover {
    background: #005ea6;
    transform: translateY(-1px);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const FirestoreError = ({ onRetry }) => {
  return (
    <ErrorContainer>
      <ErrorTitle>Service Temporarily Unavailable</ErrorTitle>
      <ErrorMessage>
        We've reached our daily server access limit. Please try again later. Donate to enable us to pay our hosting costs.
      </ErrorMessage>
      <ButtonContainer>
        {onRetry && (
          <RetryButton onClick={onRetry}>
            Try Again
          </RetryButton>
        )}
        <DonateButton 
          href="https://www.paypal.com/donate/?hosted_button_id=UBRGNLABD5ZLE" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FaPaypal /> Donate with PayPal
        </DonateButton>
      </ButtonContainer>
    </ErrorContainer>
  );
};

export default FirestoreError;
