import React, { useState, useEffect } from "react";
import styled from "styled-components";
import sanityClient from "../../sanityClient";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { BsCalendarEvent } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';

// Styled components with consistent colors
const FullWrapper = styled.div`
  background-color: #030617;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
`;

const Wrapper = styled.div`
  background-color: #030617;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  padding: 12px;
  margin: 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 8px;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  background: #1a1a1a;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.2s ease;
  margin: 0;
  padding: 0;

  &:hover {
    transform: translateY(-4px);
  }
`;

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
  margin: 0;
`;

const PostImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
  display: block;
`;

const PostContent = styled.div`
  padding: 8px;
  background: #1a1a1a;
`;

const Title = styled.h2`
  font-size: 0.75rem;
  color: white;
  margin: 0;
  font-weight: 500;
  margin-bottom: 2px;
  line-height: 1.2;
`;

const SubTitle = styled.p`
  font-size: 0.65rem;
  color: #999;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  
  svg {
    font-size: 0.8rem;
  }
`;

const PostMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  
  &:hover {
    text-decoration: none;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background-color: #3498db;
  color: white;
  padding: 8px 16px;
  margin: 0 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
`;

const PageTitle = styled.h1`
  color: #f2f2f2;
  font-size: 0.86rem;
  text-align: center;
  margin-bottom: 20px;
`;

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9); // Increased posts per page
  const [loading, setLoading] = useState(true);

  // Fetch posts from Sanity
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] {
          _id,
          title,
          "slug": slug.current,
          _createdAt,
          publishedAt,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          author->{
            name,
            "image": image.asset->url,
            bio
          },
          body
        } | order(_createdAt desc)`
      )
      .then((data) => {
        console.log('Raw post data:', data.map(post => ({
          title: post.title,
          publishedAt: post.publishedAt,
          createdAt: post._createdAt,
          author: post.author
        })));

        const sortedPosts = data.sort((a, b) => {
          const dateA = a._createdAt || a.publishedAt;
          const dateB = b._createdAt || b.publishedAt;
          return new Date(dateB) - new Date(dateA);
        });
        
        setPosts(sortedPosts);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
        setLoading(false);
      });
  }, []);

  // Get current posts for the current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <FullWrapper>
      <Wrapper>
        <PageTitle>Local Entertainment News</PageTitle>
        {loading ? (
          <SkeletonTheme color="#131C23" highlightColor="#131C23">
            <div>
              <Skeleton height={300} count={3} />
            </div>
          </SkeletonTheme>
        ) : (
          <>
            <CardContainer>
              {currentPosts.map((post) => (
                <CardWrapper key={post._id}>
                  <StyledLink 
                    to={`/post/${post?.slug}`}
                    onClick={(e) => {
                      if (!post?.slug) {
                        e.preventDefault();
                        console.error('No slug available for post:', post);
                      } else {
                        console.log('Navigating to:', `/post/${post.slug}`);
                      }
                    }}
                  >
                    <CardItem>
                      <ImgWrapper>
                        {post?.mainImage?.asset?.url && (
                          <PostImage
                            src={post.mainImage.asset.url}
                            alt={post.mainImage.alt || post.title}
                            onError={(e) => {
                              e.target.src = '/konzomoi.jpg';
                            }}
                          />
                        )}
                      </ImgWrapper>
                      <PostContent>
                        <Title>{post?.title || 'Untitled'}</Title>
                        <PostMeta>
                          <SubTitle>
                            <BsCalendarEvent />
                            {(post._createdAt || post.publishedAt) 
                              ? new Date(post._createdAt || post.publishedAt).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric'
                                })
                              : 'Date not available'}
                          </SubTitle>
                          <SubTitle>
                            <FaUser />
                            {post?.author?.name || 'Anonymous'}
                          </SubTitle>
                        </PostMeta>
                      </PostContent>
                    </CardItem>
                  </StyledLink>
                </CardWrapper>
              ))}
            </CardContainer>

            {/* Pagination Buttons */}
            <Pagination>
              {Array.from(
                { length: Math.ceil(posts.length / postsPerPage) },
                (_, index) => (
                  <PageButton
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    disabled={currentPage === index + 1}
                  >
                    {index + 1}
                  </PageButton>
                )
              )}
            </Pagination>
          </>
        )}
      </Wrapper>
    </FullWrapper>
  );
};

export default Blog;
