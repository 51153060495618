import React,{useEffect, } from 'react';
import {useLocation, Switch, Route,  } from "react-router-dom";
import Home from './components/pages/Home'
import GlobalStyle from './globalStyles' 
import Post from './components/pages/Post'
import Privacy from './components/pages/Privacy'
import Terms from './components/pages/Terms'
import About from './components/pages/About'
import Upload from './components/pages/Upload'
import Advertise from './components/pages/Advertise'
import Nonstops from './components/audio/Songs/Mp3Converter/Converter2'
import Footer2 from './components/Footer2'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
import Converter from './components/audio/Songs/Mp3Converter/Converter'
import TalentConverter from './components/audio/Songs/Mp3Converter/TalentConverter'
import NonstopPage from './components/pages/NonstopPage';
import NewTalentPage from './components/pages/NewTalentPage';
import RecentlyAddedHitsConverter from './components/audio/Songs/Mp3Converter/RecentAddedHitsConverter';
import RecentAddedHitsPage from './components/pages/RecentlyAddedHitsPage';
import ScrollToTop from './components/ScrollToTop'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Song from './components/pages/Song';

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-C8BTBL2H7M');
    // Track page views whenever location changes
    const trackPage = (page) => {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    };
    
    trackPage(location.pathname + location.search);
  }, [location] ) 
  return (
    
    <>
            
      <ScrollToTop />
      <GlobalStyle />
    
     <Switch location={location} key={location.pathname}>
     <Route path ='/radio/:id' component={TalentConverter} />
     <Route path ='/tv/:id' component={Nonstops} />
     <Route path ='/post/:slug' component={Post} />
     <Route path ='/channels/' component={NonstopPage} />
     <Route path ='/radios/' component={NewTalentPage} />
     <Route path ='/music/:id' component={Converter} />
     <Route path ='/musics/:id' component={RecentlyAddedHitsConverter} />
     <Route path ='/musics/' component={RecentAddedHitsPage} />
     <Route path ='/konzohits/' component={Song} />



     {/* <Route path='/privacy-policy' component={Privacy} />
     <Route path='/terms-and-conditions' component={Terms} />
     <Route path='/about' component={About} />
     <Route path='/contacts' component={Upload} />
     <Route path='/Advertise' component={Advertise} /> */}
     <Route path='/shop' component={() => { 
     window.location.href = 'https://flutterwave.com/store/konzovibez'; 
     return null;
}}/>
     <Route path='/jcp' component={() => { 
     window.location.href = 'https://joshcreativeprograma.netlify.app/'; 
     return null;
}}/>

<Route path='/konzomobi' component={() => { 
     window.location.href = 'https://drive.google.com/file/d/1sE13i_xxOaFX8EM4sEOhYR2j-ovxvMMj/view?usp=sharing'; 
     return null;
}}/>

<Route path='/konzomobiapp' component={() => { 
     window.location.href = 'https://drive.google.com/file/d/1sE13i_xxOaFX8EM4sEOhYR2j-ovxvMMj/view?usp=sharing'; 
     return null;
}}/>
<Route path='/tabloid' component={() => { 
     window.location.href = 'https://snowtabloid.com'; 
     return null;
}}/>
<Route path='/lightfm' component={() => { 
     window.location.href = 'https://sp14.instainternet.com/8158/stream;'; 
     return null;
}}/>
<Route path='/donate' component={() => { 
     window.location.href = 'https://gogetfunding.com/empower-kasese-through-snow-fm-a-call-for-support/'; 
     return null;
}}/>
<Route path='/whatsapp' component={() => { 
     window.location.href = 'https://chat.whatsapp.com/Inx2V5poGhj7DcDknviNsc'; 
     return null;
}}/>

     <Route path ='/' component={Home}/>

    </Switch>
    <Footer2 />

          </>
  );
}

export default App;
