import React, { useEffect, useState, useRef } from "react";
import "./Converter.css";
import Nav3 from '../../../Navs/SideView/Nav3';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import firebase from '../../../../firebase';
import styled from "styled-components";
import Modal from "./Modal";
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { BiFullscreen, BiExitFullscreen } from 'react-icons/bi';
import { MdPictureInPicture, MdPictureInPictureAlt } from 'react-icons/md';
import ViewCounter from '../../../ViewCounter';

const Container = styled.div`
  background-color: #030617;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 20px auto;
  padding: 30px;
  background: linear-gradient(145deg, #1c2237 0%, #151a2b 100%);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
`;

const ChannelInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  backdrop-filter: blur(10px);

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
`;

const ChannelImage = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: linear-gradient(45deg, rgba(75, 192, 192, 0.2), rgba(75, 192, 192, 0));
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const ChannelLogo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid rgba(75, 192, 192, 0.3);
`;

const ChannelDetails = styled.div`
  flex: 1;
`;

const ChannelName = styled.h1`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 10px;
  font-weight: 600;
  
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const LiveBadge = styled.div`
  display: inline-flex;
  align-items: center;
  background: rgba(75, 192, 192, 0.1);
  color: rgb(75, 192, 192);
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: rgb(75, 192, 192);
    border-radius: 50%;
    margin-right: 8px;
    animation: blink 1.5s infinite;
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.4; }
    100% { opacity: 1; }
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: #000;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Controls = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${PlayerWrapper}:hover & {
    opacity: 1;
  }
`;

const ControlButton = styled.button`
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(75, 192, 192, 0.8);
    transform: scale(1.1);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const AdContainer = styled.div`
  width: 100%;
  max-width: 728px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

function TVPlayer() {
  const { id } = useParams();
  const [tv, setTv] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPiP, setIsPiP] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const playerRef = useRef(null);
  const wrapperRef = useRef(null);
  const videoRef = useRef(null);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];
      return mobileKeywords.some(keyword => userAgent.includes(keyword));
    };

    setIsMobile(checkMobile());
  }, []);

  // Handle visibility change for mobile PiP
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!isMobile || !document.pictureInPictureEnabled) return;

      try {
        if (document.visibilityState === 'hidden') {
          const videoElement = videoRef.current || 
                             playerRef.current?.getInternalPlayer() || 
                             wrapperRef.current?.querySelector('video');

          if (videoElement && !document.pictureInPictureElement) {
            await videoElement.requestPictureInPicture();
          }
        } else if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
      } catch (error) {
        console.error('Failed to handle PiP on visibility change:', error);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isMobile]);

  // Handle scroll for mobile PiP
  useEffect(() => {
    let scrollTimeout;
    const handleScroll = async () => {
      if (!isMobile || !document.pictureInPictureEnabled) return;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(async () => {
        try {
          const videoElement = videoRef.current || 
                             playerRef.current?.getInternalPlayer() || 
                             wrapperRef.current?.querySelector('video');
          
          if (!videoElement) return;

          const rect = videoElement.getBoundingClientRect();
          const isVideoVisible = rect.top >= 0 && 
                               rect.bottom <= window.innerHeight;

          if (!isVideoVisible && !document.pictureInPictureElement) {
            await videoElement.requestPictureInPicture();
          } else if (isVideoVisible && document.pictureInPictureElement) {
            await document.exitPictureInPicture();
          }
        } catch (error) {
          console.error('Failed to handle PiP on scroll:', error);
        }
      }, 150); // Debounce scroll events
    };

    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', handleScroll);
        clearTimeout(scrollTimeout);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    firebase
      .firestore()
      .collection('channels')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setTv(doc.data());
        } else {
          console.log("No such document in Firebase");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    const handleFullscreenChange = () => {
      setIsFullscreen(screenfull.isFullscreen);
    };

    const handlePiPChange = (event) => {
      setIsPiP(!!document.pictureInPictureElement);
    };

    if (screenfull.isEnabled) {
      screenfull.on('change', handleFullscreenChange);
    }

    document.addEventListener('enterpictureinpicture', handlePiPChange);
    document.addEventListener('leavepictureinpicture', handlePiPChange);

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', handleFullscreenChange);
      }
      document.removeEventListener('enterpictureinpicture', handlePiPChange);
      document.removeEventListener('leavepictureinpicture', handlePiPChange);
    };
  }, [id]);

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(wrapperRef.current);
    }
  };

  const handlePiP = async () => {
    try {
      if (!document.pictureInPictureEnabled) {
        console.log('Picture-in-Picture is not enabled');
        return;
      }

      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
      } else {
        // Get the video element from ReactPlayer
        const player = playerRef.current;
        if (!player) return;

        // Try different methods to get the video element
        let videoElement = null;

        // Method 1: Direct internal player
        if (player.getInternalPlayer()) {
          videoElement = player.getInternalPlayer();
        }

        // Method 2: Query selector
        if (!videoElement) {
          videoElement = wrapperRef.current.querySelector('video');
        }

        // Method 3: Stored ref
        if (!videoElement && videoRef.current) {
          videoElement = videoRef.current;
        }

        if (!videoElement) {
          console.log('Could not find video element');
          return;
        }

        await videoElement.requestPictureInPicture();
      }
    } catch (error) {
      console.error('Failed to toggle Picture-in-Picture mode:', error);
    }
  };

  const onReady = () => {
    // Store video element reference when player is ready
    const player = playerRef.current;
    if (player) {
      const video = player.getInternalPlayer();
      if (video) {
        videoRef.current = video;
      }
    }
  };

  return (
    <>
      <Modal />
      <Nav3 />
      {tv && (
        <>
          <Helmet>
            <title>{tv.title} Live Stream</title>
            <meta name="description" content={`Watch ${tv.title} live stream - Best of Kasese entertainment`} />
            <meta property="og:title" content={`${tv.title} Live Stream`} />
            <meta property="og:image" content={tv.image} />
            <link rel="icon" href={tv.image} />
          </Helmet>
          
          <Container>
            <CardContainer>
              <ChannelInfo>
                <ChannelImage>
                  <ChannelLogo src={tv.image} alt={tv.title} />
                </ChannelImage>
                <ChannelDetails>
                  <ChannelName>{tv.title}</ChannelName>
                  <LiveBadge>LIVE</LiveBadge>
                </ChannelDetails>
              </ChannelInfo>

              <PlayerWrapper ref={wrapperRef}>
                <StyledReactPlayer
                  ref={playerRef}
                  url={tv.url}
                  controls
                  playing
                  width="100%"
                  height="100%"
                  pip={true}
                  onReady={onReady}
                  config={{
                    file: {
                      attributes: {
                        disablePictureInPicture: false,
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
                <Controls>
                  {document.pictureInPictureEnabled && (
                    <ControlButton onClick={handlePiP} title="Picture in Picture">
                      {isPiP ? <MdPictureInPictureAlt /> : <MdPictureInPicture />}
                    </ControlButton>
                  )}
                  <ControlButton onClick={handleFullScreen} title="Toggle Fullscreen">
                    {isFullscreen ? <BiExitFullscreen /> : <BiFullscreen />}
                  </ControlButton>
                </Controls>
              </PlayerWrapper>
            </CardContainer>

            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8703718386"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>

            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8456694735"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>
            <ViewCounter path={`/tv/${id}`} />
          </Container>
        </>
      )}
    </>
  );
}

export default TVPlayer;
