import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../../sanityClient";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Nav3 from "../Navs/SideView/Nav3";
import GoogleAd3 from "../GoogleAd3";
import CompliexAd from "../Compliex";
import RecentSlides from "../audio/Songs/Silder/RecentSilder";
import ViewCounter from '../ViewCounter';

const FullWrapper = styled.div`
  background-color: #030617;
  min-height: 100vh;
  padding: 20px;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #0a0e1f;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #1a1f35;

  @media (max-width: 768px) {
    padding: 15px;
    margin: 0 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #0a0e1f;
  border-radius: 12px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
`;

const LoadingContainer = styled.div`
  position: relative;
  padding: 2rem;
  background-color: #131C23;
`;

const Desc2 = styled.h1`
  display: grid;
  color: white;
  font-size: 0.90rem;
  text-align: center;
  text-decoration: none!important;
  margin-top: 18px;
  @media only screen and (max-width: 768px) {
    font-size: 0.95rem;
    display: grid;
  }
`;

const AuthorBox = styled.div`
  background: #0a0e1f;
  border-radius: 16px;
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #1a1f35;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const AuthorTitle = styled.h2`
  color: #f2f2f2;
  font-size: 1.2rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #1a1f35;
  padding-bottom: 8px;
`;

const AuthorContent = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const AuthorImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const AuthorInfo = styled.div`
  flex: 1;
`;

const AuthorName = styled.h3`
  color: #f2f2f2;
  font-size: 1.1rem;
`;

const DateText = styled.p`
  font-size: 0.9rem;
  color: #888;
  margin: 12px 0;
  font-style: italic;
`;

const BioText = styled.p`
  color: #888;
  font-size: 0.9rem;
  margin-top: 8px;
  line-height: 1.4;
`;

const ContentImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin: 1.5em 0;
  display: block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ImageCaption = styled.figcaption`
  color: #888;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5em;
  font-style: italic;
`;

const Figure = styled.figure`
  margin: 2em 0;
  padding: 0;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #f2f2f2;
`;

const serializers = {
  types: {
    block: props => {
      const {style = 'normal'} = props.node;

      if (style === 'h1') {
        return <h1 style={{ color: '#f2f2f2', marginBottom: '1em' }}>{props.children}</h1>;
      }
      if (style === 'h2') {
        return <h2 style={{ color: '#f2f2f2', marginBottom: '1em' }}>{props.children}</h2>;
      }
      if (style === 'h3') {
        return <h3 style={{ color: '#f2f2f2', marginBottom: '1em' }}>{props.children}</h3>;
      }
      if (style === 'h4') {
        return <h4 style={{ color: '#f2f2f2', marginBottom: '1em' }}>{props.children}</h4>;
      }

      return <p style={{ color: '#f2f2f2', marginBottom: '1em' }}>{props.children}</p>;
    },
    image: ({node}) => {
      if (!node?.asset?.url) {
        console.warn('Image node missing asset URL:', node);
        return null;
      }

      return (
        <Figure>
          <ContentImage
            src={node.asset.url}
            alt={node.alt || ''}
            onError={(e) => {
              console.error('Failed to load image:', node.asset.url);
              e.target.style.display = 'none';
            }}
          />
          {node.caption && <ImageCaption>{node.caption}</ImageCaption>}
        </Figure>
      );
    }
  },
  marks: {
    strong: ({children}) => <strong>{children}</strong>,
    em: ({children}) => <em>{children}</em>,
    link: ({mark, children}) => (
      <a href={mark.href} style={{ color: '#4a9eff' }} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  },
  list: props => {
    const {type} = props;
    const bullet = type === 'bullet';
    if (bullet) {
      return <ul style={{ color: '#f2f2f2', marginBottom: '1em', marginLeft: '1.5em' }}>{props.children}</ul>;
    }
    return <ol style={{ color: '#f2f2f2', marginBottom: '1em', marginLeft: '1.5em' }}>{props.children}</ol>;
  },
  listItem: props => <li style={{ color: '#f2f2f2' }}>{props.children}</li>
};


const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Attempting to fetch post with slug:', slug);
    
    if (!slug) {
      setError("No slug provided");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    // First, let's verify the query
    const query = `*[slug.current == $slug]{
      title,
      slug,
      mainImage{
        asset->{
          _id,
          url
        }
      },
      body[]{
        ...,
        _type == "image" => {
          ...,
          asset->
        }
      },
      author->{
        name,
        image{
          asset->{
            _id,
            url
          }
        },
        bio
      },
      _createdAt
    }`;

    console.log('Query:', query);
    console.log('Params:', { slug });

    sanityClient
      .fetch(query, { slug })
      .then((data) => {
        console.log("Raw response from Sanity:", data);
        setPost(data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
        setError(error);
        setIsLoading(false);
      });
  }, [slug]);

  if (error) {
    return (
      <FullWrapper>
        <ContentWrapper>
          <Title>Error: {error}</Title>
        </ContentWrapper>
      </FullWrapper>
    );
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <SkeletonTheme color="#131C23" highlightColor="#131C23">
          <Desc2>Loading...</Desc2>
          <ImageContainer>
            <StyledImage src="/konzomoi.jpg" alt="Loading image" />
          </ImageContainer>
          <Skeleton height={40} count={3} />
        </SkeletonTheme>
      </LoadingContainer>
    );
  }

  return (
    <FullWrapper>
      <Nav3 />
      {post ? (
        <Wrapper>
          <Helmet>
            <title>{post.title} | Snow Tabloid</title>
            <meta name="description" content="Snow Tabloid" />
          </Helmet>
          <ContentWrapper>
            {post?.mainImage?.asset?.url && (
              <ImageContainer>
                <StyledImage
                  src={post.mainImage.asset.url}
                  alt={post.mainImage.alt || post.title}
                  onError={(e) => {
                    e.target.src = '/konzomoi.jpg';
                  }}
                />
              </ImageContainer>
            )}
            <Title>{post.title}</Title>
            <ViewCounter path={`/post/${slug}`} />
            <DateText>
              {post._createdAt 
                ? new Date(post._createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                : 'Date not available'}
            </DateText>
            <AuthorBox>
              <AuthorTitle>Author</AuthorTitle>
              <AuthorContent>
                <AuthorImage 
                  src={post.author?.image?.asset?.url || '/default-author.jpg'} 
                  alt={post.author?.name || 'Author'} 
                  onError={(e) => {
                    e.target.src = '/default-author.jpg';
                  }}
                />
                <AuthorInfo>
                  <AuthorName>{post.author?.name || 'Anonymous'}</AuthorName>
                  {post.author?.bio && (
                    <BioText>
                      {typeof post.author.bio === 'string' 
                        ? post.author.bio 
                        : Array.isArray(post.author.bio) 
                          ? post.author.bio.map((block, i) => 
                              block.children?.map(child => child.text).join(' ')
                            ).join(' ')
                          : ''}
                    </BioText>
                  )}
                </AuthorInfo>
              </AuthorContent>
            </AuthorBox>

            {post.body && (
              <div style={{ 
                color: '#f2f2f2', 
                padding: '20px', 
                width: '100%',
                maxWidth: '800px',
                margin: '0 auto'
              }}>
                <BlockContent
                  blocks={Array.isArray(post.body) ? post.body : []}
                  serializers={serializers}
                  projectId={sanityClient.config().projectId}
                  dataset={sanityClient.config().dataset}
                  renderContainerOnSingleChild={true}
                />
                <GoogleAd3 />
              </div>
            )}
          </ContentWrapper>
        </Wrapper>
      ) : (
        <LoadingContainer>
          <SkeletonTheme color="#131C23" highlightColor="#131C23">
            <Desc2>Loading...</Desc2>
            <ImageContainer>
              <StyledImage src="/placeholder.jpg" alt="Loading image" />
            </ImageContainer>
            <Skeleton height={40} count={3} />
          </SkeletonTheme>
        </LoadingContainer>
      )}
      <CompliexAd />
      <RecentSlides />
    </FullWrapper>
  );
};

export default Post;
