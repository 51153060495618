import { useState, useEffect } from 'react';
import firebase from '../firebase';

export const useFirestore = (collection, query = null) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = () => {
    setLoading(true);
    setError(null);

    let ref = firebase.firestore().collection(collection);

    // Apply query if provided
    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        ref = ref[key](...value);
      });
    }

    return ref.onSnapshot(
      (snapshot) => {
        const items = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(items);
        setLoading(false);
      },
      (err) => {
        console.error('Firestore Error:', err);
        
        // Check for quota exceeded error
        if (err.code === 'resource-exhausted' || 
            err.message.includes('quota') || 
            err.message.includes('limit')) {
          setError('quota-exceeded');
        } else {
          setError(err.message);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const unsubscribe = fetchData();
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const retry = () => {
    fetchData();
  };

  return { data, loading, error, retry };
};

export default useFirestore;
