import React,{useState} from 'react'
import Sideview from './Sideview'
const Nav3 = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
        <Sideview/>
            
        </>
    )
}

export default Nav3
