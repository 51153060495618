import React from 'react';
import { FaAndroid, FaDownload } from 'react-icons/fa';

function AppDownloadBanner() {
  return (
    <div 
      style={{
        background: 'linear-gradient(45deg, #1DB954, #191414)',
        padding: '20px',
        margin: '20px 0',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
      }}
      onClick={() => window.open('https://play.google.com/store/apps/details?id=konzomobi.app&pcampaignid=web_share', '_blank')}
    >
      <FaAndroid size={40} style={{ marginRight: '20px' }} />
      <div>
        <h2 style={{ margin: '0', fontSize: '24px' }}>Download the Konzo Mobi App!</h2>
        <p style={{ margin: '5px 0 0 0', fontSize: '16px' }}>
          And Optimiize access to various Media Outlets in Western Uganda
        </p>
      </div>
      <FaDownload size={30} style={{ marginLeft: '20px' }} />
    </div>
  );
}

export default AppDownloadBanner;
